<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col
        ><el-breadcrumb
          ><el-breadcrumb-item class="pointer" :to="{ path: '/audioType' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col
      >
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <ul>
              <li
                class="menu-item text-cut"
                v-for="(item, i) in tabs"
                :key="item"
                :class="seeThis == i ? 'active' : ''"
              >
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('audioType')"
                            >保存</el-button
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button @click="goIndex()" size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="audioType"
                  ref="audioType"
                  label-width="150px"
                  class="audioType"
                  size="mini"
                >
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12" style="display: none">
                      <el-form-item label="id" prop="id">
                        <el-input v-model="audioType.id"></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :sm="24" :md="24" :lg="12"   >
                                                <el-form-item label="创建人" prop="createBy">
                                                    <el-input v-model="audioType.createBy"></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :sm="24" :md="24" :lg="12"   >
                                                <el-form-item label="创建时间" prop="createTime">
                                                    <el-input v-model="audioType.createTime"></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :sm="24" :md="24" :lg="12"   >
                                                <el-form-item label="更新人" prop="updateBy">
                                                    <el-input v-model="audioType.updateBy"></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :sm="24" :md="24" :lg="12"   >
                                                <el-form-item label="更新时间" prop="updateTime">
                                                    <el-input v-model="audioType.updateTime"></el-input>
                                                </el-form-item>
                                            </el-col> -->
                    <!-- <el-col :sm="24" :md="24" :lg="12"   >
                                                <el-form-item label="FM类别编码" prop="audioTypeCode">
                                                    <el-input v-model="audioType.audioTypeCode"></el-input>
                                                </el-form-item>
                                            </el-col> -->
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="FM类别名称" prop="audioTypeName">
                        <el-input v-model="audioType.audioTypeName"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="提示码" prop="promptCode">
                        <el-select
                          v-model="audioType.promptCode"
                          placeholder="请选择"
                          class="wp100"
                          value-key="promptCode"
                          @change="$forceUpdate()"
                        >
                          <el-option
                            v-for="item in promptCodeList"
                            :key="item.id"
                            :label="item.promptCode"
                            :value="item.promptCode"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="排序" prop="seq">
                        <el-input v-model="audioType.seq"></el-input>
                      </el-form-item>
                    </el-col>
                   <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="上传封面">
                      <el-upload
                      class="avatar-uploader"
                      :action="$uploadURL"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                    >
                      <img v-if="audioType.imageUrl" :src="audioType.imageUrl" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"
                        >上传照片</i
                      >
                    </el-upload>
                    </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
            <!--  多功能 -->
            <!-- <AudioTypeFunction></AudioTypeFunction> -->

            <!-- 扩展-->
            <!-- <AudioTypeExtends></AudioTypeExtends> -->
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import AudioTypeFunction from "@/views/audioType/AudioTypeFunction.vue";
import AudioTypeExtends from "@/views/audioType/AudioTypeExtends.vue";
export default {
  components: {
    AudioTypeFunction,
    AudioTypeExtends,
  },
  data() {
    return {
      // 当前页数
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      id: "",
      tabs: ["基本信息"],
      audioType: {
        id: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        audioTypeCode: "",
        audioTypeName: "",
        promptCode: "",
        seq: "",
        imageUrl:"",
      },
      promptCodeList: [],
    };
  },
  methods: {
    handleAvatarSuccess(res) {
      this.audioType.imageUrl = res.data.url;
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type == "image/jpeg";
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
    },
    getPromptCodeList() {
      let that = this;
      that.$http
        .post("/promptCode/queryList", { parentId: 0 })
        .then(function (response) {
          if (response.data.code == 200) {
            that.promptCodeList = response.data.data;
          }
        });
    },
    goIndex() {
      this.$router.push({
        path: "/audioType",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
    },
    submitForm(formName) {
      var that = this;
      // that.audioType.promptCode = that.promptCode.promptCode;
      var loginName3 = localStorage.getItem("loginName");
      var abc3 = JSON.parse(loginName3).value;
      this.audioType.createBy = abc3;
      that.$http
        .post("/audioType/save", that.audioType)
        .then(response => {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            })
             this.goIndex()
          } else {
            that.$notify.error({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        // .catch(function (error) {
        //   that.$notify.info({
        //     title: "提示",
        //     message: "保存失败",
        //     showClose: true,
        //   });
        // });
    },
    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/audioType/queryOne", { id: this.id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.audioType = response.data.data;
            }
          });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
    this.getPromptCodeList();
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
//上传封面样式
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
